@font-face {
font-family: 'bradleyHand';
src: url(/_next/static/media/52e8e465617b03a1-s.p.ttf) format('truetype');
font-display: swap;
}@font-face {font-family: 'bradleyHand Fallback';src: local("Arial");ascent-override: 92.80%;descent-override: 22.61%;line-gap-override: 12.01%;size-adjust: 90.29%
}.__className_990471 {font-family: 'bradleyHand', 'bradleyHand Fallback'
}

@font-face {
font-family: 'blackjack';
src: url(/_next/static/media/e64e89f541874e1a-s.p.ttf) format('truetype');
font-display: swap;
}@font-face {font-family: 'blackjack Fallback';src: local("Arial");ascent-override: 118.94%;descent-override: 62.28%;line-gap-override: 0.00%;size-adjust: 83.65%
}.__className_4ccc1d {font-family: 'blackjack', 'blackjack Fallback'
}

@font-face {
font-family: 'cathiesHand';
src: url(/_next/static/media/8f2a5f23af163641-s.p.ttf) format('truetype');
font-display: swap;
}@font-face {font-family: 'cathiesHand Fallback';src: local("Arial");ascent-override: 65.30%;descent-override: 14.80%;line-gap-override: 2.61%;size-adjust: 114.85%
}.__className_0b20f0 {font-family: 'cathiesHand', 'cathiesHand Fallback'
}

@font-face {
font-family: 'crappyDan';
src: url(/_next/static/media/8eac920d20235dc7-s.p.ttf) format('truetype');
font-display: swap;
}@font-face {font-family: 'crappyDan Fallback';src: local("Arial");ascent-override: 133.39%;descent-override: 67.42%;line-gap-override: 0.00%;size-adjust: 89.14%
}.__className_4be9ba {font-family: 'crappyDan', 'crappyDan Fallback'
}

@font-face {
font-family: 'dakota';
src: url(/_next/static/media/7211e33dee17e361-s.p.ttf) format('truetype');
font-display: swap;
}@font-face {font-family: 'dakota Fallback';src: local("Arial");ascent-override: 70.26%;descent-override: 35.13%;line-gap-override: 0.00%;size-adjust: 142.32%
}.__className_263c64 {font-family: 'dakota', 'dakota Fallback'
}

@font-face {
font-family: 'jennaSue';
src: url(/_next/static/media/18fc6f8d9e86e5ad-s.p.ttf) format('truetype');
font-display: swap;
}@font-face {font-family: 'jennaSue Fallback';src: local("Arial");ascent-override: 141.62%;descent-override: 47.21%;line-gap-override: 0.00%;size-adjust: 52.96%
}.__className_520de2 {font-family: 'jennaSue', 'jennaSue Fallback'
}

